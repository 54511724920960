@import '~theme/variables';

.container {
  position: absolute;
  width: 100%;
  top: 3rem;
  background-color: $surfaces-surface;
  border-radius: 0.5rem;
  padding: 0 1rem;
  z-index: 10;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 0.5rem 0.75rem 0.375rem rgba($on-surface-high-emphasis, 0.15);
}

.title {
  font-family: $font-family-bold;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: $grayscale-1;
  margin: 1rem 0 0.5rem;
}

.link {
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.125rem 0;
  border-bottom: 0.125rem solid $surfaces-background;
}

.icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.75rem;
}

.resultTitle {
  color: $grayscale-1;
  line-height: 1.125rem;
  font-family: $font-family-bold;
  font-weight: 700;
  margin: 0;
  font-size: 0.875rem;
}

.resultSubtitle {
  font-family: $font-family-medium;
  color: $on-surface-low-emphasis;
  margin: 0 0 0 0.25rem;
  font-size: 0.75rem;
}

.badge {
  border-radius: 0.25rem;
  background-color: $additional-primary-light-bg;
  font-family: $font-family-bold;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.2rem 0.25rem;
}

.webStickerContainer {
  margin: 0.25rem 0.25rem 0 0;
}

.img {
  width: 3rem;
  height: auto;
}

.productTextsContainer {
  display: flex;
  align-items: center;
}

.productSubtitle {
  @extend .resultSubtitle;
  margin: 0.25rem 0.25rem 0 0;
}
