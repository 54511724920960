@import '~theme/variables';

.imageContainer {
  display: flex;
}

.mobileLinks {
  width: 7.5rem;
  height: 2.5rem;
  margin: 0 1.375rem 0 0;
}

.img {
  aspect-ratio: attr(width) / attr(height);
}
