@import '~theme/variables';

.bannerContainer {
  align-items: center;
  background-color: rgba($surfaces-surface, 0.9);
  border-top: 0.125rem solid $grayscale-5;
  padding: 1.5rem 0;
  color: $grayscale-3;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

.content {
  max-width: 90rem;
  padding: 0 7.5rem;

  @media (max-width: 54rem) {
    padding: 0 2rem;
    max-width: 100%;
  }
}

.heading {
  font-size: 1.625rem;
  line-height: 1.875rem;
  font-weight: normal;
  color: $grayscale-1;
  margin: 0 0 1rem;
}

.textContainer {
  margin: 0 0 1rem;
  color: $grayscale-1;
  font-size: 0.875rem;
}

.container {
  width: 100%;
  max-width: 90rem;
  padding: 0 7.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;

  @media (max-width: 54rem) {
    padding: 0 1rem;
  }
}

.link {
  font-size: 1.125rem;
  color: $complementary-link;
  text-decoration: none;

  @media (max-width: 54rem) {
    margin-top: 1rem;
  }
}

.btnContainer {
  display: grid;
  grid-template-columns: 10.5rem 10.5rem;
  grid-column-gap: 1.5rem;

  @media (max-width: 54rem) {
    grid-template-columns: 10rem 10rem;
    grid-column-gap: 0.5rem;
  }
}
