@import '~theme/variables';

.container {
  background: $footer-bg-color;
}

.appSector {
  line-height: 1.625rem;
  display: flex;
  justify-content: space-around;
  border-top: 0.125rem solid $light-border;
  padding: 0.5rem 0 1.5rem;

  @media (max-width: 54rem) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
  }
}

.socialHeader {
  color: $footer-title-color;
  font-size: 1rem;
  margin: 1rem 0;
  font-family: $font-family-bold;
  line-height: 1rem;
  text-align: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.downloadAppTitle {
  color: $footer-title-color;
  margin: 1rem 0;
  font-size: 1rem;
  font-weight: 500;
  font-family: $font-family-medium;
  line-height: 1rem;
  text-align: center;
}
