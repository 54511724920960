@import '~theme/variables';

.newsletter {
  display: flex;
  width: 16rem;
  align-items: center;
  justify-content: space-between;
  background-color: $surfaces-background;
  border-radius: 2rem;
  border: 1px solid $grayscale-4;
  color: $grayscale-1;
  padding: 0 0.125rem 0 0.5rem;
  height: 2.5rem;

  &:focus-within {
    box-shadow: 0 0 3pt 2pt $grayscale-4;
  }
}

.newsletter-input {
  background-color: inherit;
  border: 0;
  font-size: 0.875rem;
  margin: 0 0.25rem 0 0;
  padding: 0;
  width: 7.5rem;
  height: 100%;
  border-radius: 2rem;
  flex: 1;
  padding-left: 0.5rem;

  &:focus {
    outline: 0;
  }
}

.newsletterButton {
  cursor: pointer;
  border: none;
  outline: none;
}

.iconNewsletter {
  color: $on-primary;
  width: 1rem;
  height: 1rem;
  bottom: 0.125rem;
  left: 2px;
  top: 3px;
  position: absolute;
}

.iconBorder {
  position: relative;
  border: 1px solid $grayscale-4;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  background-color: $grayscale-4;
}
