@import '~theme/variables';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 90rem;
  padding: 0 7.5rem;
  background-color: $footer-bottom-color;

  @media (max-width: 651px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 88.5rem) {
    padding: 0 3.5rem;
  }
  @media (max-width: 54rem) {
    padding: 0 2rem;
  }
}

.section {
  position: relative;
  background-color: $footer-bottom-color;
  padding: 1.5rem 0;
  border-top: 0.125rem solid $light-border;
}

.credits {
  flex: 1;
  color: $grayscale-3;
  font-size: 0.875rem;
  font-weight: 400;
  height: 2rem;
  line-height: 1rem;
  margin: 0.25rem 0 0;

  @media (max-width: 651px) {
    margin-bottom: 1rem;
  }
}

.text {
  margin: 0 0 0.25rem;
}

.brand {
  color: $grayscale-1;
  font-family: $font-family-bold;
  font-weight: 700;
}

.heartML {
  color: $complementary-danger;
  aspect-ratio: attr(width) / attr(height);
}

.paymentMethods {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.nbg {
  aspect-ratio: attr(width) / attr(height);
  margin: 0.5rem 0.1rem;
}

.paymentMethodImg {
  aspect-ratio: attr(width) / attr(height);
  margin: 0.5rem 0.1rem;
  width: 2rem;
  height: auto;
}

.divider {
  border: 1px solid $grayscale-4;
  height: 100%;
  margin: 0 0.5rem;
}
