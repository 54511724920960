@import '~theme/variables';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  max-width: 90rem;
  padding: 2rem;
  border-top: 0.125rem solid $light-border;

  @media (max-width: 54rem) {
    flex-direction: column;
    padding: 2.625rem 2rem 0;
  }
}

.sector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1.5rem 1rem 0;
  line-height: 1.375rem;
  font-size: 0.875rem;
  flex: 1 0 9.5rem;
}

.contactSector {
  @extend .sector;
  color: $footer-text-color;
}

.contactNumber {
  margin: 0 0 0.25rem;
}

.logo {
  height: 3rem;
  margin: 0 0 1rem;
  color: inherit;
}

.tel {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $footer-text-color;
  margin: 0 0 0.25rem;

  @media (max-width: 48rem) {
    min-height: 2.5rem;
  }

  &:hover {
    color: $footer-text-color;
  }
}

.header {
  color: $footer-title-color;
  font-size: 1rem;
  margin: 0 0 1rem;
  font-family: $font-family-bold;
}

.link {
  color: $footer-text-color;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: $font-family-medium;
  line-height: 1rem;
  text-decoration: none;
  transition-duration: 0.4s;
  align-items: center;
  margin: 0 0 0.5rem;

  @media (max-width: 48rem) {
    min-height: 2rem;
  }

  &:hover {
    color: $footer-title-color;
  }
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactIcon {
  color: $footer-text-color;
  min-width: 0.625rem;
  height: 0.625rem;
}

.logoContainer {
  max-width: 10rem;
}
