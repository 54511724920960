@import '../../scss/z-index.scss';
@import '~theme/variables';

.container {
  display: flex;
  justify-content: center;
  box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.25);
  background-color: $surfaces-surface;
  font-size: 0.875rem;

  @media (max-width: 54rem) {
    display: none;
  }
}

.shopImage {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}

.navbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.link {
  text-decoration: none;
  display: flex;
  min-width: 4rem;
  padding: 0.5rem 0.875rem;
  color: $grayscale-1;
  align-items: center;
  font-size: 0.875rem;
}

.relative {
  position: relative;
  border-right: 1px solid $light-border;
  border-bottom: 1px solid $light-border;
  padding: 0.5rem 0.625rem 0.625rem;
}

.relative:hover .submenuDropdown {
  display: block;
  width: 100%;
  height: auto;
  top: 3.125rem;
  left: 0;
  text-align: center;
  z-index: $z-navBarsubMenuItems;
}

.submenu::after {
  display: inline-block;
  margin-left: 0.255rem;
  vertical-align: 0.255rem;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.submenuDropdown {
  display: none;
  position: absolute;
  background: $surfaces-surface;
  box-shadow: $box-shadow;
}

.subnavLink {
  display: block;
  text-decoration: none;
  color: $grayscale-1;
  padding: 0.5rem;
  cursor: pointer;
}

.subnavLink:hover {
  background-color: $surfaces-background;
}

@media (max-width: 920px) {
  .relative {
    padding: 0.5rem 0.375rem 0.625rem;
  }
}
