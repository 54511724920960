@import '~theme/variables';

.button {
  cursor: pointer;
  text-align: center;
  outline: 0;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0 1rem;
  height: 2.5rem;
  box-shadow: 0 0.125rem 0.125rem 0 rgb(0 0 0 / 20%);
  font-family: $font-family-bold;
}

.primaryButton {
  background: $primary-button;
  border: none;
  color: $primary-button-text;
}

.primaryButton:disabled {
  cursor: unset;
  background-color: $primary-base;
  opacity: 0.5;
}

.brandButton {
  background: $primary-button;
  border: none;
  color: $primary-button-text;
  border-radius: $border-radius-brand-button;
}

.brandButton:disabled {
  cursor: unset;
  background-color: $primary-base;
  opacity: 0.5;
}

.secondaryButton {
  background: $secondary-button;
  color: $secondary-button-text;
  border: 1px solid $secondary-button-text;
}

.secondaryButton:hover {
  background-color: $hover;
}
