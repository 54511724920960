$font-family: 'Roboto', Helvetica, Arial, sans-serif;
$font-family-medium: 'RobotoMedium', Helvetica, Arial, sans-serif;
$font-family-bold: 'RobotoBold', Helvetica, Arial, sans-serif;
$font-family-light: 'RobotoLight', Helvetica, Arial, sans-serif;

$letter-spacing: 0;

$hover: #d5dde4;

$primary-base: #2f4486;

$primary-dark: #1c2950;
$primary-light: #7886b3;
$primary-100: #c3cae1;

$secondary-base: #e21937;

$secondary-dark: #880f21;
$secondary-light: #ee7587;

$secondary-base-muted: #ff313e;

$grayscale-1: #333;
$grayscale-2: #4b4b4b;
$grayscale-3: #7c7c7c;
$grayscale-4: #999999;
$grayscale-5: #d4d4d4;

$complementary-success: #52ae37;
$complementary-warning: #f8941e;
$complementary-link: #54659c;
$complementary-danger: #bd2214;

$additional-favorites: #ff8882;
$additional-rating: #ffda77;
$additional-suggested: #ffab73;
$additional-primary-light-bg: #e6eaf6;

// on colors
$surfaces-background: #f9f9f9;
$surfaces-surface: #fff;

$on-surface-high-emphasis: $grayscale-1;
$on-surface-low-emphasis: #696969;
$on-primary: $surfaces-surface;

$light-border: #eeeeee;

$box-shadow: 0 0.125rem 0.125rem 0 rgb(0 0 0 / 20%);
$box-shadow-modal: 0 0.125rem 0.75rem 0.125rem rgb(0 0 0 / 20%);

$border: 1px solid $grayscale-5;

// button texts
$primary-button-text: $surfaces-surface;
$secondary-button-text: $primary-base;
$secondary-button-text-secondary: $secondary-base;

// buttons background
$primary-button: $primary-base;
$secondary-button: $surfaces-surface;
$alternative-button: $secondary-base;

$border-radius-tile-cta: 3rem;
$border-radius-brand-button: 0.5rem;
$border-radius-categories-home: 50%;

// project elements
$footer-bg-color: $surfaces-surface;
$footer-bottom-color: $surfaces-surface;
$footer-title-color: $grayscale-1;
$footer-text-color: $grayscale-3;
$footer-social-bg: $grayscale-4;

$header-logo-background: $surfaces-surface;
$header-content-background: $primary-base;
$header-content-texts: $surfaces-surface;
