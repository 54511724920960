@import '~theme/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 5.125rem 0 7.25rem;
  width: 100%;
  max-width: 32.5rem;
}

.imageContainer {
  width: 100%;
  margin: 0 0 0.5rem;
}

.img {
  width: 100%;
  min-height: 5rem;
  color: $complementary-danger;
}

.errorText {
  width: 100%;
  margin: 0 0 1.85rem;
  color: $grayscale-1;
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
}

.link {
  width: 15rem;
  text-decoration: none;
  color: $on-primary;
  background-color: $complementary-link;
  text-align: center;
  line-height: 2.5rem;
  border-radius: 0.125rem;
}
