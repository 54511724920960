@import '~theme/variables';

.container {
  display: flex;
  flex: auto;
  max-width: 32rem;
  height: 2.375rem;
  position: relative;
  z-index: 6;
}

.input {
  background-color: $surfaces-surface;
  border-radius: 0.125rem 0 0 0.125rem;
  border: 0;
  color: $grayscale-1;
  flex: 1;
  font-size: 1rem;
  font-weight: 500;
  font-family: $font-family-medium;
  line-height: 1.125rem;
  margin: 0;
  padding: 0.375rem 0.75rem;
  height: 2.5rem;
  outline: 0;
}

.submit {
  display: flex;
  align-items: center;
  background: $alternative-button;
  border-radius: 0 0.125rem 0.125rem 0;
  border: 0;
  color: $on-primary;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: $font-family-medium;
  padding: 0 1.375rem;
  text-transform: uppercase;
  height: 2.5rem;
  cursor: pointer;
}

.icon {
  width: 1rem;
  height: 1rem;
  color: $on-primary;
}

.searchText {
  margin-left: 1rem;

  @media (max-width: 87.5rem) {
    display: none;
  }
}

@media (max-width: 87.5rem) {
  .img {
    margin: 0;
  }
}
