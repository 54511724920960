@import '~theme/variables';

.container {
  display: flex;
  height: 4.5rem;
  background-color: $header-content-background;
}

.modalContainer {
  text-align: center;
  pointer-events: none;
}

.modalIcon {
  width: 4rem;
  height: 4rem;
}

.modalText {
  font-size: 0.875rem;
  color: $grayscale-1;
  margin: 1rem 0 1.5rem;
  text-align: center;
}

@media (max-width: 54.25rem) {
  .container {
    height: 4rem;
  }
}

.logo {
  aspect-ratio: attr(width) / attr(height);
  cursor: pointer;
}

.leftContainer {
  display: flex;
}

.rightContainer {
  display: flex;
  align-items: center;
}

@media (max-width: 54rem) {
  .logo {
    width: 15rem;
    height: 4rem;
    margin-left: -2rem;
  }
  .rightContainer {
    justify-content: flex-end;
    flex: 0 0 10.5rem;
  }
}

@media (max-width: 22.5rem) {
  .rightContainer {
    flex: 0 0 7.5rem;
  }
}

.middleContainer {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

@media (max-width: 868px) {
  .middleContainer {
    display: none;
  }
}

.subContainer {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.middleContainerSmallScreen {
  display: none;
  padding: 1.25rem 1rem;
  background-color: $header-content-background;
  align-items: center;
  justify-content: center;

  @media (max-width: 868px) {
    display: flex;
  }
}

.navMenu {
  display: none;
}

@media (max-width: 34.375rem) {
  .modalContainer {
    padding: 0 0.5rem;
  }
}

@media (max-width: 54.25rem) {
  .subContainer {
    justify-content: flex-end;
  }
  .navMenu {
    display: block;
    position: relative;
  }
}

.logoContainer {
  background-color: $header-logo-background;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  padding: 0 2rem;
  width: 16rem;

  @media (max-width: 34.375rem) {
    padding: 0 1rem;
    width: 11.5rem;
  }
}
