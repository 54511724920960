@import '~theme/variables';

.button {
  padding: 0.625rem;
  background: $primary-base;
  border-radius: 0.25rem;
  position: fixed;
  cursor: pointer;
  z-index: 1;
  left: calc(100% - 5rem);
  top: calc(100% - 5rem);
}

.hideButton {
  display: none;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  color: $on-primary;
}
