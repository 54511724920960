@import '~theme/variables';

.link {
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.125rem 0;
  border-bottom: 0.125rem solid $surfaces-background;
}

.icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.75rem;
}

.resultTitle {
  color: $grayscale-1;
  line-height: 1.125rem;
  font-family: $font-family-bold;
  font-weight: 700;
  margin: 0;
  font-size: 0.875rem;
}

.resultSubtitle {
  font-family: $font-family-medium;
  color: $on-surface-low-emphasis;
  margin: 0 0 0 0.25rem;
  font-size: 0.75rem;
}

.badge {
  border-radius: 0.25rem;
  background-color: $additional-primary-light-bg;
  font-family: $font-family-bold;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.2rem 0.25rem;
}

.webStickerContainer {
  margin: 0.25rem 0.25rem 0 0;
  flex-shrink: 0;
}

.img {
  width: 3rem;
  height: auto;
}

.productTextsContainer {
  display: flex;
  align-items: center;
}

.productSubtitle {
  @extend .resultSubtitle;
  margin: 0.25rem 0.25rem 0 0;
  text-overflow: ellipsis;
  max-height: 1.25rem;
  overflow: hidden;
  max-width: 18rem;
  white-space: nowrap;

  @media (max-width: 30rem) {
    max-width: 10rem;
  }
}
