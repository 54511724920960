@import '~theme/variables';

%header__rightButton {
  min-width: 4rem;
  height: 100%;
  padding: 0 1.5rem;
  border: 0;
  flex: 1 0 4rem;

  cursor: pointer;

  &:hover {
    background-color: $primary-light;
  }

  @media (max-width: 54rem) {
    min-width: 0;
    flex: 0 0 4rem;
    padding: 0 1rem;
  }

  @media (max-width: 25rem) {
    min-width: 3rem;
    flex: 0 0 3rem;
    padding: 0;
  }

  @media (max-width: 22.5rem) {
    min-width: 2.5rem;
    flex: 0 0 2.5rem;
  }
}
