@use '../../scss/breakpoints';
@import '../../scss/header.scss';
@import '~theme/variables';

.cart {
  @extend %header__rightButton;

  display: flex;
  justify-content: center;
  align-items: center;

  color: $header-content-texts;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: $font-family-medium;
  line-height: 1.25rem;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    background-color: $primary-light;
  }
}

.cartHeaderContainer {
  height: 100%;
  z-index: 7;
}

.cartContainer {
  position: absolute;
  right: 0;

  @media (max-width: breakpoints.$mdlg) {
    right: -4rem;
    top: 9.25rem;
  }

  @media (max-width: 25rem) {
    right: -3rem;
    top: 0;
  }

  @media (max-width: 22.5rem) {
    right: -2.5rem;
  }
}

.isFullPageCart {
  background-color: #3282b880;
}

.cartIconContainer {
  position: relative;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 0.375rem 0 0;

  @media (max-width: 54.25rem) {
    margin: 0;
  }
}

.numProductsText {
  position: absolute;
  border-radius: 50%;
  line-height: 1.125rem;
  font-size: 0.75rem;
  width: 1.125rem;
  height: 1.125rem;
  top: -0.625rem;
  right: 0;
  background-color: $alternative-button;

  @media (max-width: 64rem) {
    right: -0.5rem;
  }
}

.text {
  @media (max-width: 64rem) {
    display: none;
  }
}
