@import '~theme/variables';

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0 1rem 0 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  max-width: 13.25rem;
  flex: 0 0 13.25rem;

  @media (max-width: 72rem) {
    flex: 0 0 2rem;
    padding: 0 0.5rem;
    margin: 0 1rem 0 0;
    @media (max-width: 30rem) {
      display: none;
    }
  }
}

.text {
  color: $header-content-texts;
  margin: 0 0 0 0.5rem;

  @media (max-width: 72rem) {
    display: none;
  }
}

.icon {
  width: 1.125rem;
  height: 1.125rem;
  color: $header-content-texts;
}
