@import '~theme/variables';

.socialHeader {
  color: $footer-title-color;
  font-size: 1rem;
  margin: 1rem 0;
  font-family: $font-family-bold;
  line-height: 1rem;
  text-align: center;
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialItem {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $footer-social-bg;
  border-radius: 1.75rem;
  height: 2.5rem;
  width: 2.5rem;
  text-align: center;
  cursor: pointer;
  margin: 0 0.25rem 0 0;

  &:hover {
    transform: scale(1.2);
    transition-duration: 0.3s;
  }
}

.socialIcon {
  color: $footer-bg-color;
  margin-bottom: 0.125rem;
  height: 1.25rem;
  width: 1.25rem;
  margin-top: 0.125rem;
}
