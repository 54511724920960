@import '../../scss/header.scss';
@import '~theme/variables';

.user {
  @extend %header__rightButton;

  display: flex;
  align-items: center;
  justify-content: center;

  color: $header-content-texts;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: $font-family-medium;
  line-height: 1.25rem;
  text-decoration: none;
}

.nameText {
  font-family: $font-family-bold;
  font-weight: 700;
}

.icon {
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem 0 0;
}

.iconUser {
  width: 2rem;
  height: 2rem;
  margin: 0 0.5rem 0 0;
}

@media (max-width: 58.75rem) {
  .text {
    display: none;
  }
}

@media (max-width: 54.25rem) {
  .icon,
  .iconUser {
    margin: 0;
  }

  .user {
    padding: 0;
  }

  .text {
    display: none;
  }
  .nameText {
    display: none;
  }
}
